@import "../../../styles/theme.module.css";

:root{
    --radius: 200px;
}
.container{
    width: var(--radius);
    height: var(--radius);
    background-color: var(--accent);
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.container img{
    width: 100%;
}

@media only screen and (max-width: 1200px) {
    :root{
        --radius: 150px;
    }

}
@media only screen and (max-width: 750px) {
    :root{
        --radius: 100px;
    }
}