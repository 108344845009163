@import "../../../styles/theme.module.css";

.container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width:500px;
    /* height:600px; */
    background-image: linear-gradient(-10deg, var(--primary) 00%, var(--accent3) 100%);
    /* background-color: var(--primary); */
    border-radius: 25px;
    box-shadow:0px 10px 100px 100px var(--accent3);
    padding: 15px;
}

.mapWrapper{
    width: 100%;
    height: 300px;
    border-radius: 15px;
    background-color: var(--primary2);
}
.mapWrapper iframe{
    border: 0;
    border-radius: 15px;
    height: 100%;
    width: 100%;
}

.selector{
    width: 100%;    
    height: 75px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}
.loc{
    color:white;
    cursor: pointer;
}
/* .loc.active{
    color:#FCCE01;
    text-shadow: 0px 5px 5px rgba(0, 0, 0, 0.5);
    transform: translateY(-5px);
    transition:all 0.1s;
} */
.info{
    width: 90%;
    display: flex;
    flex-direction:column;
    justify-content: flex-start;
    align-items: left;
}
.info .item{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.info h3{
    color:var(--primary2);
    text-align: left;
}
.info img{
    width: 20px;
    height: 20px;
    margin-right: 10px;
}
.item{
    color:var(--primary2);
    margin-bottom: 10px;

}
@media only screen and (max-width: 1200px) {

}
@media only screen and (max-width: 750px) {
    /* .mapWrapper{
        width: 95%; 
    } */
    /* .info{
        width: 70%;
    } */
}

@media only screen and (max-width: 600px) {
    .container{
        width: 90%;
    }
    .info h3{
        font-size: 1em;
    }
    .item{
        margin-bottom: 0;
    }
}