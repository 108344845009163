@import "../../../styles/theme.module.css";

:root{
  --boxshadow:-10px 5px 10px rgba(0, 0, 0, 0.6);
}

.container {
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2em;

  position: absolute;
  top: 0%;
  left: 40%;
  /* transform: translateY(-50%); */
  /* border: 1px solid red; */

  /* background-color: white; */
}
.one {
  position: absolute;
  top: 0%;
  left: 0%;

  width: 300%;
  height: 100%;
  z-index: 2;
  background-color: white;
  opacity: 1;
  transform: skewX(-20deg);

  box-shadow: var(--boxshadow);
}
.two {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: var(--primary);
  opacity: 0.5;
  transform: skewX(-20deg) translateX(-100px);
  box-shadow: var(--boxshadow);
}

.motto {
  font-size: 3em;
  font-weight: 900;

  background: var(--primary);
  background: linear-gradient(60deg, var(--accent2) 0%, var(--primaryMotto) 50%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 2px 2px 2px rgba(255, 255, 255, 0.2);

  z-index: 5;
}
@media only screen and (max-width: 1600px) {
  .motto {
    font-size: 2em;
  }
}
@media only screen and (max-width: 1200px) {
  .motto {
    font-size: 1.6em;
  }
}
@media only screen and (max-width: 850px) {
  .container {
    flex-direction: column;
  }
  .motto {
    font-size: 2em;
    /* border: 1px solid red; */
  }
}
@media only screen and (max-width: 650px) {
  .one, .two{
    display: none;
  }
  .container {
    width: 100%;
    left: 0%;
    /* border: 1px solid red; */
    background: rgba(255, 255, 255, 0.3);
    /* border-radius: 16px; */
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    
  }
  .motto {
    /* text-shadow: none; */
    font-size: 2.5em;
    align-self: flex-start;
    margin-left: 40%;
    /* margin-right: 50%; */
    text-wrap: nowrap;
    transform: translateX(-15vw);
    /* border: 1px solid red; */

  }

}
@media only screen and (max-width: 400px) {
  .motto {
    /* text-shadow: none; */
    font-size: 2em;
  }
}