@import "../../../../styles/theme.module.css";

.container {
    position: relative;
    /* height: 150px;
    width: 150px; */
  
    border-radius: 7px;
  
    font-weight: bold;
    text-align: center;
  
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  
    box-shadow: 1px 5px 10px rgba(0, 0, 0, 0.25);
    background-image: linear-gradient(
      180deg,
      var(--accent3) 15%,
      var(--primary) 100%
    );
    cursor: pointer;
    /* background-color: var(--primary); */
    color: var(--primary2);
    transition: color 1s;
}
.container:active{
    transform: scale(0.95);
}

.img {
  /* width: 100%; */
  height: 150px;
  /* position: absolute;
  inset: 0; */

  /* border: 2px solid var(--secondary); */
  border-radius: 5px;
  object-fit: cover;
  z-index: 0;
}

.play{
  width: 40px !important;
  height: 40px;
  position: absolute;
  bottom: 10px;
  right: 10px;
  cursor: pointer;
}
.play:active{
  transform: scale(0.9);
}
.trash{
  width: 50px !important;
  height: 50px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.trash:active{
  transform: scale(0.95);
}