@import "../../styles/theme.module.css";

.container {
  width: 100%;
  height: 100px;
  background-color: var(--primary2);
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;

  /* box-shadow: 0px 10px 10px 10px rgba(0,0,0,0.25); */
  /* border: 2px solid red; */
  position: fixed;
  top: 0;
  left: 0;

  transform: translateX(0px);
  z-index: 10;
}

.logo {
  width: 300px;
  margin-left: 30px;
}

.nav {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  gap: 50px;
  /* margin-right: 100px; */

  
}

.link {
  color: var(--secondary);
  text-decoration: none;
}
.active {
  font-weight: bolder;
  background: var(--primary);
  background: linear-gradient(to right, var(--accent2) 0%, var(--primary) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.menu{
  margin-right: 50px;
  display: none;
  /* border: 1px solid black; */
}
.menuIcon {
  display: none;
}

@media only screen and (max-width: 1200px) {
  .container {
    position: fixed;
    justify-content: space-between;
    opacity: 0.99;
    transform: translateX(1px);
    z-index: 1000;
  }
  .nav {
    width: 100px;
    padding: 20px;
    position: absolute;
    top: 100%;
    right: 0;
    flex-direction: column;
    align-items: flex-start;
    height: 500px;
    background-color: var(--primary2);
    border: 1px solid var(--accent);
    opacity: 0.99;
    transform: translate(1px);
    z-index: 1000;
    display: none;

  }
  .menu{
    display: block;
  }
  .menuIcon {
    width: 50px;
    cursor: pointer;
    /* height: 100px; */
    align-self: center;
    filter: brightness(0) saturate(100%) invert(59%) sepia(68%) saturate(750%)
      hue-rotate(182deg) brightness(90%) contrast(85%);
    display: block;
  }
}

@media only screen and (max-width: 750px) {
  .logo{
    width: 200px;
  }
  .nav {
    gap: 20px;
    height: auto;
  }
  .link{
    margin: 0;
  }
}