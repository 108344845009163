@import "./CareersInfo.module.css";

.container {
  width: 35%;
  height: 100%;
  padding-left: 5%;
  padding-right: 5%;

  background: linear-gradient(180deg, var(--accent2) 0%, var(--primary) 50%);
  /* background-color: var(--primary); */

  /* box-shadow: 10px 0px 10px 10px rgba(0,0,0,0.25); */

  /* z-index: 1; */
}

.container h2 {
  color: var(--primary2);
  margin-top: 100px;
}
@media only screen and (max-width: 1000px) {
  .container {
    width: 80%;
    padding: 10%;
  }
}
@media only screen and (max-width: 750px) {
  .container {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    padding: 10%;
  }
  .container h2 {
    font-size: 1em;
    margin-top: 50px;

  }
}
