@import "../../../styles/theme.module.css";

:root {
  --top: 100px;
  --left: 100px;
  --width: 0px;
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 15px 30px 15px 30px;

  background-color: var(--primary);
  color: var(--primary2);
  font-weight: bold;
  text-decoration: none;
  text-decoration-line: none;

  border: none;
  border-radius: 15px;
  cursor: pointer;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  opacity: 0.99;

  overflow: hidden;
}

.container:active {
  transform: scale(0.98);
}

.container:hover {
  text-shadow: 1px 1px 1px black;
}
.container::after {
  content: "";
  opacity: 1;
  width: var(--width);
  height: var(--width);
  border-radius: 50%;
  background-color: var(--accent2);
  /* background-image:linear-gradient(to left, var(--yellow), var(--red)); */

  position: absolute;
  top: var(--top);
  left: var(--left);
  transition: width 1s ease, height 1s ease, border-radius 0.5s ease;
  transform: translate(-50%, -50%);
  z-index: -1;
}
