@import "../../styles/theme.module.css";

.container {
  width: 100%;
  background-color: var(--primary2);
  padding-top: 100px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.container h1 {
  color: var(--primary);
  align-self: flex-start;
  margin-left: 125px;
  margin-bottom: 100px;
}
.container p {
  /* color: var(--secondary); */
  margin-left: 150px;
  margin-right: 150px;

  font-weight: bold;
}
.section1 {
  height: 900px;
}
.row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}
.col {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  /* border: 1px solid black; */
}

.box {
  width: 60%;
  background-color: var(--shade2);
  border-radius: 7px;

  /* padding-top: 20px;
  padding-bottom: 20px; */
  font-weight: bold;
  margin: 7px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.box h3 {
  color: var(--primary);
  /* margin-left: 50px;
  margin-right: 50px; */
  /* margin-top: 20px; */
  font-weight: bold;
  text-align: center;
  vertical-align: middle;

}
.box p {
  color: var(--secondary);
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 20px;
  text-align: center;
  font-weight: bold;
}
.box li {
  color: var(--secondary);
  align-self: flex-start;
  font-size: 1em;

  margin-left: 10%;
  margin-right: 5%;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: normal;
}
.smallBoxes {
  width: 60%;
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-left: 3%;
  padding-right: 3%;
  /* border: 1px solid black; */
}
.smallBox {
  /* flex: 1; */
  width: 150px;
  height: 100px;
  border-radius: 10px;
  margin: 5px;

  background-color: var(--accent);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.smallBox img {
  width: 30px;
  filter: brightness(0) saturate(100%) invert(72%) sepia(77%) saturate(2850%)
    hue-rotate(181deg) brightness(91%) contrast(82%);

  /* border: 1px solid black; */
}
.smallBox h4 {
  margin: 0;
  color: var(--primary);
}

.item {
  min-height: 40px;
}

.bigCircle {
  background-image: linear-gradient(
    140deg,
    var(--accent3) 30%,
    var(--primary) 60%
  );
  color: var(--primary2);

  width: 400px;
  height: 400px;
  padding: 100px;
  border-radius: 50%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  text-align: center;
}

.circle {
  width: 150px;
  height: 150px;
  background-image: linear-gradient(
    140deg,
    var(--accent2) 30%,
    var(--primary) 60%
  );
  color: var(--primary2);

  /* width: 100px; */
  /* height: 100px; */
  padding: 10px;
  border-radius: 50%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  text-align: center;

  position: absolute;
  top: 500px;
  left: 60%;
}
.circle img {
  width: 40%;
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7480%)
    hue-rotate(144deg) brightness(109%) contrast(105%);
}
.relative {
  position: relative;
}
.position {
  position: absolute;
  /* border: 1px solid black; */
}
.circle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 1300px) {
  /* .circle{
    display: none;
  } */
}
@media only screen and (max-width: 1200px) {
  .row {
    display: flex;
    /* border: 1px solid red; */
    flex-direction: column;
  }
  .section1 {
    height: auto;
  }
  .box {
    /* min-width: 500px; */
  }
  .col {
    width: 100%;
    height: auto;
    margin-bottom: 200px;
  }
  .col2 {
    width: 100%;
    margin-bottom: 200px;
  }
  .section2 {
    height: auto;
  }
  .smallBox {
    margin-bottom: 100px;
  }
}
@media only screen and (max-width: 1000px) {
  .row {
    display: flex;
    /* border: 1px solid red; */
    flex-direction: column;
  }
  .section1 {
    height: auto;
  }
  .box {
    /* min-width: 500px; */
  }
  .col {
    width: 100%;
    height: auto;
    margin-bottom: 200px;
  }
}

@media only screen and (max-width: 750px) {
  .container h1 {
    margin: 0;
    width: 100%;
    text-align: center;
    font-size: 1.2em;
    margin-bottom: 50px;
  }
  .container {
    padding-bottom: 50px;
  }
  .circle {
    display: none;
  }
  .bigCircle {
    width: 75vw;
    height: 75vw;
    padding: 10%;
    font-size: 0.8em;
  }
  .item {
    width: 80%;
    height: auto;
    font-size: 0.8em;
  }
  .item2 {
    width: 80%;
    height: auto;
    font-size: 0.8em;
    padding: 0;
  }
  .item2 h3 {
    margin: 10px;
  }
  .box {
    width: 80%;
  }
  .box p {
    font-size: 0.7em;
    /* text-align: left; */
    padding: 0;
    margin: 15px 20px;
  }
  .box h3 {
    /* margin-bottom: 10px; */
  }
}
