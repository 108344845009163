@import "../../../styles/theme.module.css";

.container{
    position:fixed;
    inset: 0;
    width:100vw;
    height: 100vh;

    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    overflow:hidden;
    transform: translate(0px);
    z-index:10000;
}

.backdrop{
    position:fixed;
    inset: 0;
    width:100vw;
    height: 100vh;

    /* cursor: pointer; */
    
    background:rgba(0, 0,0,0.5);

    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    overflow:hidden;
    transform: translate(0px);
    z-index:10000;
}

.content {
    transform: translate(0px);
    z-index:10001;

}