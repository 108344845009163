@import "../../styles/theme.module.css";

:root {
  --height: 750px;
}

.container {
  width: 100%;
  height: var(--height);
  background-color: var(--shade);
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}

.imgCol {
  padding: 0;
  width: 55%;
  /* height: var(--height); */
}
.infoCol {
  width: 45%;
  height: 100%;
  /* height: var(--height); */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  background-color: var(--primary);
  box-shadow: -10px 0px 10px 10px rgba(0, 0, 0, 0.5);
  /* border: 1px solid red; */
}
.infoCol p {
  color: var(--primary2);
  text-align: center;
  font-size: 1.5em;
  margin: 50px;
}

.primaryColor {
  /* color: var(--accent2); */
  /* font-weight: bold; */
}
.imgCol img {
  /* width: 100%; */
  object-fit: cover;
  /* height: var(--height); */
}

@media only screen and (max-width: 1500px) {
  .infoCol {
    width: 60%;
  }
  .imgCol {
    width: 40%;
    /* height: var(--height); */
  }
  .infoCol p {
    font-size: 1.2em;
    padding-left: 10%;
    padding-right: 10%;
  }
}

@media only screen and (max-width: 1200px) {
  .container {
    height: auto;
    flex-direction: column-reverse;
  }
  .infoCol {
    width: 100%;
    padding: 50px;
  }
  .infoCol p {
    text-align: left;
    font-size: 1.2em;
    padding-left: 20%;
    padding-right: 20%;
  }
  .imgCol {
    width: 100%;
    /* height: var(--height); */
  }
}
@media only screen and (max-width: 850px) {
  .infoCol p {
    width: 90%;
    text-align: left;
    font-size: 0.8em;
    padding-left: 10%;
    padding-right: 10%;
  }
  .imgCol {
    display: none;
  }
}
