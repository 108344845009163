@import "../../styles/theme.module.css";

.container{
    width: 100%;
    background-image: linear-gradient(-40deg, var(--primary) 60%, var(--accent2) 100%);
    /* background-color: var(--shade); */

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap-reverse;

    padding-top: 100px;
    padding-bottom: 100px;
}
.satelliete{
    position: relative;
    width: 500px;

}
.image{
    width: 100%;
}
.mapInfo{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
}

.location{
    width: 50px;
    position: absolute;
    left: 25%;
    top: 39%;



    filter: brightness(0) saturate(100%) invert(92%) sepia(1%) saturate(0%) hue-rotate(87deg) brightness(111%) contrast(101%);}