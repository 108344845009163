@import "../../styles/theme.module.css";

.container {
    width: 100%;
    min-height: 350px;
    background-color: var(--primary2);
  
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    transform: translateX(0px);
    color:var(--primary);
  }
  /* .container img {
    width: 100px;
    opacity: 0.05;
    transition: opacity 30s 0s ease-out;
  } */
  .container img:hover {
    opacity: 1;
  }
  .column {
    width: 80%;
    /* background-color:red; */
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .connect {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .connect h1 {
    color:var(--primary);
    font-weight: 500;
  }
  .socials {
    width: 100%;
    /* background-color:blue; */
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 50px;
    flex-wrap: wrap;
  }
  .socials img {
    /* cursor: pointer; */
    height: 75px;
    opacity: 1;
    transition: opacity 0.1s;
    object-fit: contain;
  
  }
  /* .socials img:hover{
      opacity: 0.5;
  } */
  .connect h6 {
    color:var(--primary);
  }
  .yellow {
    color:var(--primary);
  }
  

  @media only screen and (max-width: 1200px) {
    .column{
      width: 80%;

    }
    .empty{
      display: none;
    }
  }
  @media only screen and (max-width: 750px) {
    .socials img{
      max-width: 150px;
    }
  }