@import "../../styles/theme.module.css";

.container {
  width: 100%;
  background-color: var(--primary2);

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.colW {
  width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.box {
  width: 600px;
  background-color: var(--shade2);
  color: var(--secondary);
  box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.1);

  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 25px;
  padding: 50px;
}

.box h1 {
  /* color: var(--primary); */
  background: var(--primary);
  background: linear-gradient(to right, var(--accent2) 0%, var(--primary) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.box p {
  margin-bottom: 30px;
}
.row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;

  margin-bottom: 200px;
}
.icon {
  width: 100px;
}
.primaryColor {
  color: var(--primary);
  /* font-weight: bold; */
}

@media only screen and (max-width: 1200px) {
  .container {
    justify-content: space-evenly;
  }
  .colW {
    width: 30%;
  }
  .box {
    width: 400px;
  }
  .box p {
    font-weight: regular;
    font-size: 0.9em;

  }
}

@media only screen and (max-width: 750px) {
  .row {
    flex-direction: column;
    gap: 20px;
  }
  .colW {
    width: 80%;
  }
  .box {
    width: 70%;
    z-index: 10;
    opacity: 0.99;
  }
}

@media only screen and (max-width: 600px) {
    .box{
      width: 95%;
      padding: 10px;
    }
}
