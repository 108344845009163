@import "../../../styles/theme.module.css";

.container{
    width: 45%;
    padding-left: 5%;
    padding-right: 5%;
    /* padding-bottom: 200px; */
    height: 100%;
    background-color: var(--primary2);

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    /* border: 1px solid black; */
    /* box-shadow: 10px 10px 10px 10px rgba(0,0,0,0.25); */

}

.label{
    color: var(--primary);
    align-self: flex-start;
    margin-top: 30px;
    margin-bottom: 10px;
}

.row{
    width: 100%;
    /* padding-left: 20%; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}
.container .btn{
    width: 150px;
    margin-top: 30px;
    font-size: 1.1em;
}
@media only screen and (max-width: 1200px) {
    .container{
        width: 80%;
        padding: 10%;
    }
}