@import "./styles/theme.module.css";

*{
  text-decoration: none;;
}
.App{
  width: 100%;
  /* height: 20000px; */
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--primary2);
  overflow-x: hidden;
  overflow-y: hidden;
}


