@import "../../styles/theme.module.css";

.container{
    width: 100%;
    background-color: var(--primary2);
    min-height: 300px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 50px;
    /* border: 1px solid red; */

    padding-top: 200px;
    padding-bottom: 200px;

}
.msgContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* border: 2px solid red; */

    

}
.tri{
    width: 0px;
    height: 0px;
    /* background-color: var(--secondary); */


    border-left: 30px solid var(--accent);
    border-right: 30px solid transparent;
    border-top: 30px solid transparent;
    border-bottom: 30px solid transparent;

    transform: rotate(45deg) translateX(81%) translateY(-10%);
    align-self: flex-end;
}
.msg{
    width: 800px;
    min-height: 300px;

    background-color: var(--accent);
    border-radius: 50px 50px 50px 0px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 50px;

    /* box-shadow: 5px 4px 4px 0px rgba(0,0,0,0.25); */
}

.msg p{
    width: 100%;
    color: var(--secondary);
    font-size: 1.2em;
}
.msg p b{
    color: var(--primary);
}

.profile{
    align-self: flex-end;
}
.col{
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    /* border: 1px solid black; */
}

.col h4{
    margin-top:10px;
    margin-bottom:5px;
    color: var(--primary);
}
@media only screen and (max-width: 1200px) {
    .msg{
        width: 70%;
        min-height: 300px;
        padding: 20px;
        border-radius: 20px 20px 20px 0px;

    }
    .container{
        gap: 15px;
    }
    .msg p{
        width: 100%;
        color: var(--secondary);
        font-size: 1em;
    }
}
@media only screen and (max-width: 750px) {
    .col h4{
        width: 100%;
        text-align: center;
        font-size: 0.8em;
        
    }
    .msg{
        border-radius:20px;
    }
    .msg p{
        width: 100%;
        color: var(--secondary);
    }
    .container{
        flex-direction: column;

    }
    .col{
        width: 100%;
        justify-content: center;
        /* background-color: red; */
    }
    .profile{
        justify-content: center;
        align-self: center;
    }
    .tri{
        display: none;
    }

}
@media only screen and (max-width: 600px) {
    .msg{
        width: 90%;
        min-height: 300px;
        border-radius:20px;
        /* margin-right: 10px; */
    }
    .msg p{
        font-size: 0.9em;
    }
}