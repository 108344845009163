@import "../../../styles/theme.module.css";

.container {
  width: 35%;
  height: 100%;
  padding-left: 5%;
  padding-right: 5%;

  background: linear-gradient(-120deg, var(--accent2) 10%, var(--primary) 40%);
  /* background-color: var(--primary); */
  color: var(--primary2);

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  box-shadow: -5px 0px 25px 10px rgba(0,0,0,0.25);
  overflow-x: hidden;
}

.mapWrapper {
  width: 100%;
  height: 400px;
  border-radius: 15px;
  margin-top: 100px;
  background-color: var(--primary2);
}
.mapWrapper iframe {
  border: 0;
  border-radius: 15px;
  height: 100%;
  width: 100%;
}

.info {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
}
.info .item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.info h3 {
  color: var(--primary2);
  text-align: left;
}
.info img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.item {
  color: var(--primary2);
  margin-bottom: 10px;
}
@media only screen and (max-width: 1200px) {
  .container{
      width: 80%;
      padding: 10%;
  }
}
@media only screen and (max-width: 750px) {
  .container{
    width: 95%;
  }

}