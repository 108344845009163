.wrapper {
  height: 850px;
  /* width: 60%; */
  /* background: #555; */
  /* padding: 50px; */
  /* position: absolute; */
  top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;

  /* border: 1px solid red; */

  /* transform: rotateX(-20deg) rotateZ(5deg); */

  /* gap: 20px; */
  overflow-y: hidden;
  overflow-x: visible;
}

/* .carousel {
  background: blue;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
} */

.box {
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: green; */
  height: 250px;
  width: 175px;
  margin: 0;
  margin-bottom: 20px;
  padding: 0;
  position: relative;
  flex-shrink: 0;
  color: black;
  font-size: 21px;
  cursor: pointer;
  border-radius: 10px;
  z-index: 1;
  /* box-shadow: 0px 4px 10px white; */
  filter: brightness(0.8);
  transition: filter 0.2s;
  overflow: hidden;
}

.box:hover{
  filter: brightness(1);
}

.box img {
  pointer-events: none;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
  /* border-radius: 25px; */

  
}



.prev {
  position: absolute;
  top: 10%;
  left: 15%;
}
.next {
  position: absolute;
  top: 10%;
  right: 15%;
}
button {
  padding: 1rem 2rem 1rem 2rem;
  border: none;
  /* background: #555; */
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
}
.toggle {
  position: absolute;
  top: 10%;
}

.show-overflow {
  overflow: visible;
}

.middle-item {
  /* background: red !important; */
}
