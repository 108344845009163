@import "../../../styles/theme.module.css";

.container{
    width: 80%;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 100px;
    padding-bottom: 100px;
    min-height: 500px;

    background-color: var(--primary2);

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 10px;

}

.imgCard{
    /* width: 200px; */
    height: 200px;
    object-fit: cover;
    cursor: pointer;

    /* border-radius: 5px; */
    overflow: hidden;

    transition: transform 0.2s ease;
}
.imgCard:hover{
    transform: translateY(-10px);
}

.imgCard:active{
    transform: translateY(-5px);
}

.imgCard img{
    width:100%;
    height: 100%;
}
