@import "../../styles/theme.module.css";

.container {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  /* background: linear-gradient(180deg, var(--accent2) 0%, var(--primary) 79%); */

  background-color: var(--primary);

  padding-bottom: 75px;
}

.container h1 {
  color: var(--primary2);
  margin-bottom: 50px;
}

.menu {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 100px;

  /* margin: 50px; */
}

.btn {
  width: 250px;
  height: 250px;

  background: linear-gradient(180deg, var(--accent3) 0%, var(--primary) 79%);
  /* background-color: var(--primary); */
  /* background: linear-gradient(0deg, var(--accent2) 0%, var(--primary) 79%); */
  color: var(--primary2);
  text-shadow: 1px 1px rgba(0, 0, 0, 0.5);
  font-weight: bold;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: box-shadow 0.2s ease-out, color 0.3s ease-in;
}
.btn:hover {
  text-shadow: none;
  box-shadow: 0px 4px 200px 0px var(--accent);
  color: var(--primary);
}
.btn::after {
  background-color: var(--shade);
}

.btn img {
  width: 100px;
  filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(22%)
    hue-rotate(311deg) brightness(105%) contrast(106%);
}
.btn:hover img {
  width: 100px;
  /* filter: brightness(0) saturate(100%) invert(14%) sepia(31%) saturate(4359%)
    hue-rotate(210deg) brightness(105%) contrast(101%); */

  /* #5B9BD5 */
  filter: brightness(0) saturate(100%) invert(55%) sepia(38%) saturate(632%)
    hue-rotate(167deg) brightness(98%) contrast(84%);
}
.btn h3 {
  font-size: 1em;
  text-decoration: none;
}

@media only screen and (max-width: 1200px) {
}
@media only screen and (max-width: 1000px) {
  .menu {
    gap: 30px;
  }
  .btn {
    width: 200px;
    height: 200px;
  }
  .btn img {
    width: 50px;
  }
}

@media only screen and (max-width: 700px) {
  .btn {
    width: 150px;
    height: 150px;
  }
  .btn h3 {
    width: 150px;
    font-size: 0.7em;
    text-decoration: none;
    /* background-color: red; */
    /* margin: ; */
  }
  .btn img {
    width: 50px;
  }
}
