@import "../../styles/theme.module.css";

.container {
  width: 60%;
  padding-left: 20%;
  padding-right: 20%;
  padding-top: 50px;
  min-height: 1000px;
  background-image: linear-gradient(
    180deg,
    var(--accent3) 0%,
    var(--primary) 50%
  );
  background-color: var(--primary);
  color: var(--primary);

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.container h1 {
  color: var(--primary2);
  align-self: flex-start;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.list {
  width: 80%;
  padding-left: 10%;
  padding-right: 10%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
  margin-bottom: 200px;
}
.publicationCard {
  width: 100%;
  min-height: 100px;
  /* padding: 10px; */

  border-radius: 10px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  background-color: var(--primary2);
  font-family: "Times New Roman", Times, serif;

  /* cursor: pointer; */

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  transition: min-height 1s ease;
}

.publicationCard .col {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 15px;
}
.publicationCard h2 {
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
  margin-left: 5px;
}

.publicationCard .info {
  flex: 8;
  /* border: 1px solid black; */
}
.publicationCard .info p {
  margin-top: 20px;
  color: var(--secondary);
  margin-left: 20px;
  opacity: 0;
  display: none;
}
.publicationCard:hover .info p {
  opacity: 1;
  display: block;
}

.publicationCard .options {
  flex: 2;

  /* border: 1px solid black; */
  align-self: flex-end;
}

.note {
  color: var(--secondary);
  /* margin: 10px; */
  display: block;
}
.publicationCard:hover .note {
  color: var(--secondary);
  display: none;
  /* margin: 10px; */
}
.file {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: var(--primary);
  color: var(--primary2);
  border-radius: 5px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: none;
  cursor: pointer;
}
.file:hover {
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.25);
  background-color: var(--accent3);
}

.publicationCard:hover .file {
  display: block;
}

.publicationCard:hover {
  /* min-height: 200px; */
}

@media only screen and (max-width: 1200px) {
  .container {
    width: 80%;
    padding-left: 10%;
    padding-right: 10%;
  }
}
@media only screen and (max-width: 750px) {
  .container {
    width: 100%;
    padding: 0;
  }
  .container h1 {
    margin-left: 20px;
  }
  .publicationCard h2 {
    font-size: 0.8em;
  }
  .publicationCard .info p {
    font-size: 0.7em;
  }
  .publicationCard  {
    flex-direction: column;
  }
}
