@import "../../styles/theme.module.css";

.container {
  width: 80%;
  background-color: var(--shade);
  padding-top: 100px;
  padding-bottom: 100px;
  padding-left: 10%;
  padding-right: 10%;

  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;

  /* border-top: 5px solid var(--secondary); */
}

.logo {
  width: 80%;
}

.col {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* flex-grow: 1; */

  /* border: 2px solid black; */
}

.col .gradientBorder {
  position: relative;
  width: 100%;
  height: 200px;
  margin-top: 50px;
  background-image: linear-gradient(to left, var(--primary), var(--accent2));
  border-radius: 15px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  transition: all 0.5s ease;
}

.gradientBorder .white {
  position: absolute;
  inset: 5px;
  border-radius: 10px;
  background-color: white;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--secondary);

  text-align: center;
  padding: 10px;
}

.col h1 {
  font-size: 2em;
  background: var(--primary);
  /* background: linear-gradient(to right, var(--accent2) 0%, var(--primary2) 50%); */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.col p {
  color: var(--secondary);
  text-align: center;
  font-size: 1.2em;
}

.primaryColor {
  color: var(--primary);
  /* font-weight: bold; */
}

.col:nth-child(2) {
  /* background-image: radial-gradient(var(--accent3) ,var(--primary) 70%); */
}
.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px;
  font-size: 1em;
}
.circle1 {
  position: absolute;
  top: -10%;
  left: 0;
  z-index: 10;
}
.circle2 {
  position: absolute;
  top: -5%;
  right: 20%;
  z-index: 10;
}
.circle3 {
  position: absolute;
  top: 50%;
  right: -5%;
  z-index: 10;
}
.circle4 {
  position: absolute;
  bottom: 5%;
  left: 30%;
  z-index: 10;
}
.circle5 {
  position: absolute;
  top: -35%;
  left: 30%;
  z-index: 10;
}
.circle6 {
  position: absolute;
  top: -35%;
  right: 30%;
  z-index: 10;
}
.circle7 {
  position: absolute;
  top: 35%;
  right: -15%;
  z-index: 10;
}
.circle8 {
  position: absolute;
  top: 60%;
  left: -5%;
  z-index: 10;
}
.circle9 {
  position: absolute;
  bottom: -20%;
  left: 50%;
  z-index: 10;
}
.hoverCircles {
  opacity: 0;
  transition: opacity 0.2s;
}
.gradientBorder:hover {
  background-image: linear-gradient(to right, var(--primary), var(--accent2));
  transform: translateY(-20px) scale(1.01);
  box-shadow: 0px 8px 8px 4px rgba(0, 0, 0, 0.25);
}
.gradientBorder:hover .hoverCircles {
  opacity: 1;
}

.white p {
  font-weight: bold;
}
@media only screen and (max-width: 700px) {
  .logo {
    width: 100%;
  }
  .col p {
  font-size: 0.8em;
  }
  .btn{
    padding: 10px 15px;
  }
}
@media only screen and (max-width: 1200px) {
  .container {
    flex-direction: column;
    gap: 50px;
  }
  .col {
    width: 100%;
  }
  .logo {
    width: 80%;
  }
}
