@import "../../../styles/theme.module.css";

.container{
    width:50%;
    height: 800px;
    border-radius: 25px;
    border: 1px solid var(--secondary);
    box-shadow: inset 10px 10px 40px rgba(1, 1, 1, 0.4);
    background-image: linear-gradient(120deg, var(--accent2) 0%, var(--primary) 50%);
}
.react-flow__node {
    color: var(--secondary);
    background: var(--accent);
    font-weight: bold;
    /* text-shadow: 1px 1px black; */
    box-shadow: 2px 4px 10px rgba(1, 1, 1, 0.2);
}
.react-flow__handle{
    opacity: 0;
}

@media only screen and (max-width: 1500px) {
    .container{
        width: 80%;
    }
}
@media only screen and (max-width: 1200px) {
    .container{
        width: 80%;
        height: 500px;
    }
}
@media only screen and (max-width: 600px) {
    .container{
        width: 95%;
        height: 400px;
    }
}