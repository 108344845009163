@import "../../styles/theme.module.css";

.container {
    width: 100%;
    height: 100px;
    background-color: var(--shade);
    
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.menu{
    width: 70%;
    display:flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items:center;
}

.link{
    color: var(--secondary);
    text-decoration: none;
}
.menu h4:active{
    color: var(--primary);
}
.active{
    color: var(--primary);
}

@media only screen and (max-width: 1200px) {
    .menu{
        width: 100%;
    }
    .link{
        font-size: 0.8em;
    }
}
@media only screen and (max-width: 750px) {
    .container{
        height: auto;
    }
    .menu{
        width: 100%;
        flex-direction: column;
        /* border: 1px solid red; */
    }
    .link{
        width: 90%;
        font-size: 1em;
        text-align: center;
        /* border: 1px solid green; */
        text-align: start;
    }
}
