@import "../../styles/theme.module.css";

.container {
  width: 100%;
  height: 850px;
  background-color: var(--primary2);
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
@media only screen and (max-width: 1300px) {
}
@media only screen and (max-width: 1200px) {
}

@media only screen and (max-width: 1000px) {
  .container {
    height: auto;
    flex-direction: column;
  }
}
@media only screen and (max-width: 750px) {
  .collectionCard {
    width: 150px;
    height: 150px;
  }
  .container {
    width: 94%;
    padding-left: 3%;
    padding-right: 3%;
  }
  .collections {
    padding: 0;
  }
}
