@import "../../styles/theme.module.css";

.container{
    width: 100%;
    height: 850px;
    background-color: var(--primary2);

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
@media only screen and (max-width: 1200px) {
    .container{
        flex-direction: column;
        height: auto;
        width: 100%;
    }
}