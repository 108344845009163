:root{
    --primary: #307bdc;
    --secondary: #404244;
    --accent: #cbdbef;
    --accent2:#34c6ee;
    --accent3:#93c1ec;
    --shade: #efefef;
    --shade2:#eeeeee;
    --primary2: #fff;
    --yellow: #FCCE01;
    --red: #EA4335;
    --primaryShadow: #0c3b79;
    --primaryMotto: rgb(0, 88, 204);
}
/* :root{
    --primary: #10217d;
    --secondary: #63696A;
    --accent: #ececf4;
    --accent2:#3c84fb;
    --accent3:#0b3d8d;
    --shade: #F9F9F9;
    --primary2: #fff;
} */
/* FONT COLORS */
.primaryColor{
    color: var(--primary);
}
.primary2Color{
    color: var(--primary2);
}
.secondaryColor{
    color: var(--secondary);
}


/* BACKGROUND COLORS */
.primaryBackground{
    background-color: var(--primary);
}
.primary2Background{
    background-color: var(--primary2);
}
.secondaryBackground{
    background-color: var(--secondary);
}
.accentBackground{
    background-color: var(--accent);
}
.shadeBackground{
    background-color: var(--shade);
}