@import "../../../styles/theme.module.css";

.container {
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 100px;
  width: 80%;
  min-height: 500px;
  margin: 0;
  /* border: 1px solid red; */
  background-image: linear-gradient(180deg, black 15%, var(--primary) 100%);

  color: var(--primary2);
}

.container h1 {
  margin: 0;
}

.collections {
  width: 100%;
  height: 80%;
  /* border: 1px solid red; */

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  padding: 50px;
  flex-wrap: wrap;
  margin-bottom: 100px;
}

.collectionCard {
  height: 300px;
  width: 300px;

  padding: 10px;
  border-radius: 7px;

  font-weight: bold;
  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  box-shadow: 1px 5px 10px rgba(0, 0, 0, 0.25);
  background-image: linear-gradient(
    180deg,
    var(--accent3) 15%,
    var(--primary) 100%
  );
  /* background-color: var(--primary); */
  color: var(--primary2);
  transition: color 1s;
}
.collectionCard:hover {
  color: var(--primary2);
}

.collectionCard img {
  height: 100%;
  width: 100%;
  position: absolute;
  inset: 0;

  border: 2px solid var(--secondary);
  border-radius: 5px;
  object-fit: cover;
  margin-bottom: 10px;
  z-index: 0;
}

.collectionCard h2 {
  width: 100%;
  height: 20%;
  position: absolute;
  bottom: -10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: rgba(0, 0, 0, 0.25); */
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.5) 50%,
    rgba(0, 0, 0, 0) 100%
  );

  opacity: 0;
  /* border: 2px solid var(--secondary); */
  z-index: 1;

  transition: opacity 0.3s ease, bottom 0.3s ease;
}

.collectionCard:hover h2 {
  opacity: 1;
  bottom: 0px;
}

.list {
  width: 100%;
  /* outline: 1px dotted red; */

  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  padding-bottom: 100px;
}
@media only screen and (max-width: 1300px) {
}
@media only screen and (max-width: 1200px) {
}
@media only screen and (max-width: 750px) {
  .collectionCard {
    width: 150px;
    height: 150px;
  }
  .container {
    width: 94%;
    padding-left: 3%;
    padding-right: 3%;
  }  
  .collections{
    padding: 0;
  }
}
