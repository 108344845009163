@import "../../styles/theme.module.css";

.container {
  width: 100%;
  background-color: var(--primary2);
  height: 40vw;
  min-height: 500px;

  display: flex;
  flex-direction: row;
  position: relative;
  overflow: hidden;
}

@media only screen and (max-width: 800px) {
  .container {
    height: 80vw;
  }
}
