@import "../../../styles/theme.module.css";

.container {
  width: 100%;
  height: 850px;

  background-color: black;
  color: var(--primary2);

  display: flex;
  justify-content: flex-end;
  align-items: center;

  overflow: hidden;
}
.title {
  position: absolute;
  top: 10%;
  left: 10%;
  /* border: 1px solid red; */
  width: 50%;
  /* height: 100%; */

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  opacity: 0.99;
  z-index:100;
}
.titleContainer {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.title h1 {
  font-size: 4em;
  margin-top: 200px;
  text-shadow: 1px 2px black;
}
.title p {
  color: var(--primary2);
  opacity: 0.8;
  font-size: 1.5em;
  margin-bottom: 50px;
  text-shadow: 1px 2px black;

}
.carousel {
  /* border: 1px solid red; */

  position: relative;
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;

  transform: skewX(-15deg);
}

.carousel::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100px;
  bottom: -10px;
  left: 0;
  border-bottom:2px solid black;
  /* background-color: red; */
  background-image: linear-gradient(
    180deg,
    rgba(160, 210, 232, 0) 0%,
    rgba(0, 0, 0, 1) 80%
  );

  transform: translate(1px);
  z-index: 1;
}

.btn {
  box-shadow: 1px 4px 5px 1px rgba(255, 255, 255, 0.2);
  transition: all 1s ease;
  align-self: center;
}
.btn:hover {
  transform: translateY(-10px);
  box-shadow: 1px 10px 30px 15px rgba(255, 255, 255, 0.2);
  text-shadow: none;
  font-weight: bold;
  color: var(--primary);
}

.btn::after {
  background-color: var(--primary2);
}
@media only screen and (max-width: 1200px) {
  .title {
    width: 70%;
  }
  .carousel {
    width: auto;
    transform: skewX(-15deg) translateX(15%);
  }
  .carousel::after{
    left: -10%;
    width: 110%;
  }
}