@import "../../styles/theme.module.css";

.container {
  width: 100%;
  /* background-color: green; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 300px;
  margin-top: 300px;
}

.container h1 {
  font-size: 4em;
  /* margin-bottom: 200px; */
  font-weight: bolder;
  background: var(--primary);
  background: linear-gradient(to right, var(--accent2) 0%, var(--primary) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media only screen and (max-width: 1200px) {
  
}

@media only screen and (max-width: 750px) {
  .container h1 {
    font-size: 2em;
  }  
}

@media only screen and (max-width: 600px) {
    
}
