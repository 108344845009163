@import "../../../styles/theme.module.css";

.container{
    min-width: 50%;
    height: 100%;
    background-color: var(--primary);
    /* border-radius: 50%; */

    padding: 2vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    overflow: hidden;

    position:absolute;
    top:  0;
    left: 0;

    box-shadow:inset -20px 10px 50px rgba(0,0,0,0.8);

    /* border: 1px solid red; */
}

.img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    height: 100%;
    height:100%;
    width:100%;
    /* border-radius: 50%; */
    object-fit: cover;
    box-shadow:inset -20px 10px 50px rgba(0,0,0,0.5);
}
.shadow{
    position: absolute;
    top: 50%;
    left: 50%;
    /* background-color: red; */
    transform: translate(-50%,-50%);
    height: 100%;
    height:100%;
    width:100%;
    /* border-radius: 50%; */
    object-fit: cover;
    box-shadow:inset -30px 30px 20px 0px rgba(0,0,0,0.65);
    /* border: 2px solid var(--primaryShadow); */
}

@media only screen and (max-width: 850px) {
    .container{
        min-width: 60%;
    }

}

@media only screen and (max-width: 650px) {
    .container{
        min-width: 100%;
    }

}